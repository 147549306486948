<template>
  <div>
    <h2 class="text-xl font-bold">
      Follow me:
    </h2>

    <div class="my-7 grid grid-flow-row">
      <a class="prettyLink h-8 max-w-fit" href="https://twitter.com/filip_hric">
        <IconTwitter class="mr-2 inline w-5 pb-1" /><p class="inline text-left">
          Twitter
        </p>
      </a>
      <a class="prettyLink h-8 max-w-fit" href="https://www.linkedin.com/in/filip-hric">
        <IconLinkedin class="mr-2 inline w-5 pb-1" /><p class="inline text-left">
          LinkedIn
        </p>
      </a>
      <a class="prettyLink h-8 max-w-fit" href="https://bsky.app/profile/filiphric.com">
        <IconBluesky class="mr-2 inline w-5 pb-1" /><p class="inline text-left">
          Bluesky
        </p>
      </a>
      <a class="prettyLink h-8 max-w-fit" href="https://www.threads.net/@filiphric_">
        <IconThreads class="mr-2 inline w-5 pb-1" /><p class="inline text-left">
          Threads
        </p>
      </a>
      <a class="prettyLink h-8 max-w-fit" href="https://discord.com/invite/3MdvPfT">
        <IconDiscord class="mr-2 inline w-5 pb-1" /><p class="inline text-left">
          Discord
        </p>
      </a>
      <a class="prettyLink h-8 max-w-fit" href="https://github.com/filiphric">
        <IconGithub class="mr-2 inline w-5 pb-1" /><p class="inline text-left">
          GitHub
        </p>
      </a>
      <a class="prettyLink h-8 max-w-fit" href="https://www.youtube.com/@filip_hric">
        <IconYoutube class="mr-2 inline w-5 pb-1" /><p class="inline text-left">
          YouTube
        </p>
      </a>
      <a class="prettyLink h-8 max-w-fit" href="/rss.xml">
        <IconRss class="mr-2 inline w-5 pb-1" /><p class="inline text-left">
          RSS feed
        </p>
      </a>
    </div>
  </div>
</template>
