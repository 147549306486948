<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="bg-ivory-dark dark:bg-black-dark">
    <div class="mx-auto mt-14 grid max-w-screen-xl grid-cols-12 place-content-evenly gap-7 px-7 py-14 md:px-10 lg:px-20">
      <FooterSubscribeForm class="col-span-12 md:col-span-4 md:row-span-2" />
      <FooterSocialIcons class="col-span-6 md:col-span-4" />
      <FooterAbout class="col-span-6 md:col-span-4" />
      <FooterSitemap class="col-span-6 md:col-span-4" />
    </div>
  </div>
</template>
