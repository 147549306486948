<template>
  <div class="grid grid-flow-row place-items-center gap-14 text-4xl font-semibold md:grid md:grid-flow-col md:gap-7 md:place-self-end md:text-xl md:font-medium">
    <NavBarItem title="Home" link="/" class="md:hidden" />
    <NavBarItem title="Blog" link="/blog" />
    <NavBarItem title="Workshops" link="/workshops" />
    <NavBarItem title="Courses" link="/courses" />
    <NavBarItem title="Community" link="https://discord.com/invite/3MdvPfT" />
    <NavBarItem title="About" link="/about" />
    <AuthState />
  </div>
</template>